// src/components/Layout.js
import React from 'react';
import '../App.css';
import Navigation from './Navigation';

const Layout = ({ children }) => {
    return (
        <div>
            {/* Header */}
            <header alt="privat logoped göteborg">
                <h1>LOGOPEDPRAKTIKEN</h1>
                <h1 id="small-header">GÖTEBORG</h1>
            </header>

            {/* Navigation */}
            <Navigation />

            {/* Main Content */}
            <main>{children}</main>

            
        </div>
    );
};

export default Layout;
