// Home.js
import React from "react";
import Layout from "./Layout";
import line from "../images/line B.png";

const Home = () => {
  return (
    <Layout>
      <div className="main-div">
        <h2>Välkommen till LOGOPEDPRAKTIKEN</h2>
        <p className="under-title">Privat logoped i Göteborg</p>
        <img src={line}alt=""id="line" />
        <p className="main-text">
          Vi på Logopedpraktiken brinner för att göra skillnad för personer i
          behov av stöd kring ätande, kommunikation och utmanande beteende. Vår
          starka övertygelse är att en viktig del i detta arbete är att ge den
          närmsta omgivningen kunskap och strategier. Detta bidrar dels till att
          skapa en ökad förståelse för problematiken, men ger också redskap att
          kunna bemöta den. Vi erbjuder utbildning och handledning, men även
          individuella insatser i form av kartläggning och behandling. <br />
          <br />I menyn ovan kan du läsa mer om oss och om vilka tjänster och
          insatser som Logopedpraktiken erbjuder. Logopedpraktikens verksamhet
          utgår från Göteborgområdet, men vi tar också uppdrag från andra delar
          av landet. Vid frågor eller funderingar är du varmt välkommen att höra
          av dig, ett formulär för detta finns under menyvalet "Kontakt".
        </p>
      </div>
    </Layout>
  );
};

export default Home;
