import React from "react";
import line from "../images/line B.png";
import envelope from "../images/envelope.png";
import Layout from "./Layout";
import { useForm, ValidationError } from "@formspree/react";

const Contact = () => {
  const [state, handleSubmit] = useForm("mnqkelar");
  if (state.succeeded) {
    return (
      <Layout>
        <div className="main-div">
          <h2>KONTAKT</h2>
          <img src={line} alt="" id="line" />
          <p className="under-title">
            Tack för ditt meddelande!<br/> Vi hör av oss inom
            fem arbetsdagar med ett förslag på hur vi går vidare. 
          </p>
          <img src={envelope} alt="envelope" id="envelope" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="main-div">
        <h2>KONTAKT</h2>
        <img src={line} alt="" id="line" />
        <p className="under-title">
          Vad roligt att du vill komma i kontakt med oss! Vi ser fram emot att
          hjälpa dig.
        </p>
        <p className="main-text">
          Fyll i nedanstående formulär med dina kontaktuppgifter och kort
          information om vad du önskar stöd kring. Tänk på att inte ange
          personuppgifter eller andra känsliga uppgifter. Vi hör av oss inom fem
          arbetsdagar med ett förslag på hur vi går vidare. Det går också bra
          att skicka ett mail till oss på info@logopedpraktiken.se.
        </p>
        <form
          onSubmit={handleSubmit}
          action="https://formspree.io/f/mnqkelar"
          method="POST"
        >
          <label htmlFor="name">Namn</label>
          <input id="name" type="text" name="name" />
          <label htmlFor="email">E-post</label>
          <input id="email" type="email" name="email" />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <label htmlFor="message">Meddelande</label>
          <textarea id="message" name="message" />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
          <button type="submit" disabled={state.submitting}>
            Skicka
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default Contact;
