import React from "react";
import Layout from "./Layout";
import line from "../images/line B.png";

const About = () => {
  return (
    <Layout>
      <div className="main-div">
        <h2>OM OSS</h2>
        <img src={line} alt="" id="line" />
        <p className="main-text">
          Vi som driver Logopedpraktiken heter <b>Sofia Larsfelt</b> och 
          <b> Hanna Andersson</b>, är logopeder, och har sedan 2008 arbetat inom
          habiliteringen. Vi har även erfarenhet av arbete på logopedmottagning,
          inom personlig assistans, förskola/skola samt äldreomsorg och
          LSS-verksamhet. Vår förhoppning är att kunna vara ett komplement eller
          ett alternativ till annan hälso- och sjukvård samt att sprida kunskap
          och lära ut strategier. Vi erbjuder insatser direkt till personen i
          behov av stöd samt till anhöriga och personal, exempelvis inom
          LSS-verksamhet, personlig assistans, skola, förskola, öppen förskola,
          bibliotek, äldreomsorg, BVC och annan hälso- och sjukvårdspersonal. Vi
          har fördjupat oss främst inom områdena ätande, språk, kommunikation,
          AKK och utmanande beteende.
          <br /> <br />
          Vi har kunskap och erfarenhet inom; <br />
          <ul>
            <li>Barns språk- och kommunikationssvårigheter</li>

            <li>SOS approach to feeding</li>

            <li>Dysfagi</li>

            <li>
              Ät- och sväljsvårigheter (ej anorexi eller bulimi) hos barn,
              ungdomar och vuxna.
            </li>

            <li>
              Funktionsnedsättningar (som autism, Downs syndrom, Cerebral
              pares, flerfunktionsnedsättning, rörelsehinder och olika sällsynta
              diagnoser)
            </li>

            <li>
              AKKtiv (Alternativ och Kompletterande Kommunikation tidig
              intervention)
            </li>

            <li>
              TAKK
              <br />
            </li>

            <li>Samtalsmatta</li>

            <li>PODD (Pragmatisk Organisering Dynamisk Display)</li>

            <li>TaSSelS</li>

            <li>Seriesamtal och Sociala berättelser</li>

            <li>TBA (Tillämpad Beteendeanalys)</li>

            <li>Kommunikationsstöd vid utmanande beteende</li>

            <li>
              Hjälpmedelsprogram såsom InPrint, Widgit Go, TD Snap, Grid 3 och
              Communicator (Vi förskriver inte hjälpmedel, men har god kunskap
              för att kunna stötta kring implementering och användning av både
              låg- och högteknologisk AKK).
            </li>
          </ul>
        </p>
      </div>
    </Layout>
  );
};

export default About;
